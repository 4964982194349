const images = document.querySelectorAll('.lazy-img');
for (const image of images) {
	const i = new Image();
	i.onload = () => {
		image.src = image.dataset.src;
		image.classList.remove('lazy-js');
	};
	i.src = image.dataset.src;
	console.log(i, image.dataset.src);
}

(function () {
	const contactForm = document.getElementById('contact-form');
	const contactButton = document.getElementById('contact-submit');
	const contactAlert = document.getElementById('contact-alert');

	const showClass = 'contact__alert--show';
	const errorClass = 'contact__alert--error';
	const successClass = 'contact__alert--success';
	const contactClasses = [showClass, errorClass, successClass];

	if (contactForm) {
		contactForm.onsubmit = function (e) {
			e.preventDefault();

			var payload = {
				name: document.getElementById('name').value,
				username: document.getElementById('username').value,
				email: document.getElementById('email').value,
				product: document.getElementById('product').value,
				message: document.getElementById('message').value,
				'g-recaptcha-response': grecaptcha.getResponse(),
			};

			for (var i = 0; i < contactClasses.length; ++i) {
				contactAlert.classList.remove(contactClasses[i]);
			}
			contactButton.disabled = true;
			contactButton.textContent = 'Sending Message...';

			fetch('/contact', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			})
				.then(function (res) {
					contactButton.disabled = false;
					contactButton.textContent = 'Send Message';

					if (res.status === 204) {
						contactAlert.textContent = 'Successfully sent message! We will respond in a few days.';
						contactAlert.classList.add(showClass, successClass);
					} else {
						contactAlert.textContent = 'Failed to send message. Please try again later.';
						contactAlert.classList.add(showClass, errorClass);
					}
				})
				.catch(function (error) {
					console.error(error);
					contactButton.disabled = false;
					contactButton.textContent = 'Send Message';

					contactAlert.textContent = 'Failed to send message: ' + error.toString();
					contactAlert.classList.add(showClass, errorClass);
				});
		};
	}
})();
